<template>
  <span @click="logsFlag ? '' : handleOpen()">
    <el-badge :value="logsFlag ? '' : logsLen" :max="99">
      <i class="icon-rizhi1"></i>
    </el-badge>
    <el-dialog title="通知" v-model="box" width="60%" append-to-body>
      <el-button type="danger" icon="el-icon-delete" @click="clear">清空通知</el-button>
      <el-table :data="noticeList">
        <el-table-column prop="type" label="类型" width="50px"> </el-table-column>
        <el-table-column prop="url" label="地址" show-overflow-tooltip width="180">
        </el-table-column>
        <el-table-column prop="message" show-overflow-tooltip label="内容"> </el-table-column>
        <el-table-column prop="stack" show-overflow-tooltip label="错误堆栈"> </el-table-column>
        <el-table-column prop="time" label="时间"> </el-table-column>
      </el-table>
    </el-dialog>
  </span>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'firm-top-notification',
  data() {
    return {
      box: false,
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters(['userInfo']),
  },
  props: [],
  methods: {
    handleOpen() {
      this.box = true;
    },
    clear() {
      this.$confirm('确定清空通知记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$store.commit('CLEAR_NOTICE');
          this.box = false;
          this.$message({
            type: 'success',
            message: '清空成功!',
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.code {
  font-size: 12px;
  display: block;
  font-family: monospace;
  white-space: pre;
  margin: 1em 0px;
}
</style>
