import EduLayout from '@/page/index/eduLayout.vue';

export default [
  // 教育门户首页（独立路由，不使用布局）
  {
    path: '/edu/home',
    name: '教育门户',
    meta: {
      i18n: 'eduPortal',
      menuType: 'edu',
      visibilityScope: 'edu',
      role: ['admin', 'administrator'],
      noLayout: true  // 不使用默认布局
    },
    component: () => import('@/views/education/edu/home.vue')
  },
  // 教育系统（使用教育布局）
  {
    path: '/edu',
    component: EduLayout,
    redirect: '/edu/index',
    children: [
      {
        path: 'index',
        name: '教育系统首页',
        meta: {
          i18n: 'eduIndex',
          menuType: 'edu',
          visibilityScope: 'edu',
          role: ['admin', 'administrator']
        },
        component: () => import('@/views/education/school/index.vue'),
      },
      {
        path: 'schoolInit',
        name: '学校初始化',
        meta: {
          i18n: 'schoolInit',
          menuType: 'edu',
          visibilityScope: 'edu',
          role: ['admin', 'administrator']
        },
        component: () => import('@/views/education/school/schoolInit.vue'),
      },
      // 学生管理
      {
        path: 'studentManage',
        name: '学生管理',
        meta: {
          i18n: 'student',
          role: ['admin', 'administrator'],
          visibilityScope: 'edu',
          menuType: 'edu',
          isTab: false
        },
        component: () => import('@/views/education/student/studentManage.vue'),
      },
      {
        path: 'courseManage',
        name: '课程管理',
        meta: {
          i18n: 'course',
          isMenu: 'true',
          role: ['admin', 'administrator','teacher'],
          visibilityScope: 'edu',
          menuType: 'edu',
        },
        component: () => import('@/views/education/course/courseManage.vue'),
      },
      // 作业考试管理
      {
        path: 'examManage',
        name: '作业考试管理',
        meta: {
          i18n: 'homework',
          role: ['admin', 'administrator','teacher'],
          visibilityScope: 'edu',
          menuType: 'edu',
          isMenu: 'true',
        },
        component: () => import('@/views/education/exam/examManage.vue'),
      },
      {
        path: 'classManage',
        name: '班级管理',
        meta: {
          i18n: 'class',
          role: ['admin', 'administrator','teacher'],
          visibilityScope: 'edu',
          menuType: 'edu',
          isMenu: 'true',
        },
        component: () => import('@/views/education/class/classManage.vue'),
      }
    ]
  }
];
