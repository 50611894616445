import { getToken } from '@/utils/auth'
import crypto from '@/utils/crypto'

// 获取基础 URL
const getBaseUrl = () => {
  // 在生产环境使用环境变量中的 API URL
  if (import.meta.env.PROD) {
    return import.meta.env.VITE_GW || ''
  }
  // 在开发环境使用相对路径
  return '/api'
}

class SseClient {
  constructor() {
    this.eventSource = null
    this.userId = null
    this.clientId = null  // 初始化时不立即生成 clientId
    this.messageHandlers = new Map()
    // 添加重连相关配置
    this.reconnectAttempts = 0
    this.maxReconnectAttempts = 10  // 最大重连次数
    this.reconnectBaseDelay = 1000  // 初始重连延迟时间 (1秒)
    this.reconnectMaxDelay = 30000  // 最大重连延迟时间 (30秒)
    this.reconnectTimer = null
    this.isReconnecting = false
    this.autoReconnect = true  // 默认启用自动重连
  }

  // 初始化客户端标识符（在用户登录后调用）
  initClientId(userId) {
    const sessionId = 'session_' + Date.now() + '_' + Math.random().toString(36).substr(2, 9)
    this.clientId = `${userId}_${sessionId}`
    localStorage.setItem('sse_client_id', this.clientId)
    return this.clientId
  }

  // 清除客户端标识符（在用户登出时调用）
  clearClientId() {
    localStorage.removeItem('sse_client_id')
    this.clientId = null
  }

  // 获取客户端标识符
  getClientId() {
    if (!this.clientId) {
      this.clientId = localStorage.getItem('sse_client_id')
    }
    return this.clientId
  }

  // 连接SSE
  connect(userId) {
    if (this.eventSource) {
      this.disconnect()
    }

    this.userId = userId
    // 确保有 clientId，如果没有则初始化一个
    if (!this.getClientId()) {
      this.initClientId(userId)
    }
    
    const token = getToken()
    const sa = crypto.encryptAES(token, crypto.cryptoKey)
    const baseUrl = getBaseUrl()
    const sseUrl = `${baseUrl}/sc-sse/sse/connect/${encodeURIComponent(userId)}?clientId=${encodeURIComponent(this.clientId)}&Sc-Auth=${encodeURIComponent('crypto ' + sa)}`
    this.eventSource = new EventSource(sseUrl)

    // 连接建立时的处理
    this.eventSource.onopen = () => {
      console.log('SSE connection established')
      // 重置重连计数器
      this.reconnectAttempts = 0
      this.isReconnecting = false
    }

    // 处理连接错误
    this.eventSource.onerror = (error) => {
      console.error('SSE connection error:', error)
      
      // 如果启用了自动重连，尝试重新连接
      if (this.autoReconnect && !this.isReconnecting) {
        this.tryReconnect()
      } else {
        this.disconnect()
      }
    }

    // 处理 connect 事件
    this.eventSource.addEventListener('connect', (event) => {
      console.log('SSE connect event:', event.data)
    })

    // 处理 message 事件
    this.eventSource.addEventListener('message', (event) => {
      try {
        if(event.data.includes("keep-alive")) {
          return
        }
        const data = JSON.parse(event.data)
        const messageType = data.messageType
        const handler = this.messageHandlers.get(messageType)
        
        // 确保处理函数存在后再调用
        if (handler && typeof handler === 'function') {
          handler(data)
        } else {
          console.warn(`No handler registered for message type: ${messageType}`)
        }
      } catch (error) {
        console.error('Error processing SSE message:', error, event.data)
      }
    })
  }

  // 尝试重新连接
  tryReconnect() {
    // 如果已经在尝试重连或已达到最大重连次数，则不再尝试
    if (this.isReconnecting || this.reconnectAttempts >= this.maxReconnectAttempts) {
      if (this.reconnectAttempts >= this.maxReconnectAttempts) {
        console.error(`Maximum reconnection attempts (${this.maxReconnectAttempts}) reached. Giving up.`)
        this.disconnect()
      }
      return
    }

    this.isReconnecting = true
    this.reconnectAttempts++

    // 使用指数退避算法计算下一次重连延迟
    const delay = Math.min(
      this.reconnectBaseDelay * Math.pow(1.5, this.reconnectAttempts - 1),
      this.reconnectMaxDelay
    )

    console.log(`Attempting to reconnect (${this.reconnectAttempts}/${this.maxReconnectAttempts}) in ${delay}ms...`)

    // 清除现有的计时器
    if (this.reconnectTimer) {
      clearTimeout(this.reconnectTimer)
    }

    // 设置新的重连计时器
    this.reconnectTimer = setTimeout(() => {
      console.log(`Reconnecting... (Attempt ${this.reconnectAttempts}/${this.maxReconnectAttempts})`)
      // 清空现有连接
      if (this.eventSource) {
        this.eventSource.close()
        this.eventSource = null
      }
      // 重新连接
      this.connect(this.userId)
    }, delay)
  }

  // 断开SSE连接
  disconnect() {
    // 清除重连计时器
    if (this.reconnectTimer) {
      clearTimeout(this.reconnectTimer)
      this.reconnectTimer = null
    }
    
    this.isReconnecting = false
    
    if (this.eventSource) {
      this.eventSource.close()
      this.eventSource = null
      this.userId = null
      console.log('SSE connection closed')
    }
  }

  // 设置重连配置
  setReconnectConfig(config = {}) {
    if (config.maxReconnectAttempts !== undefined) {
      this.maxReconnectAttempts = config.maxReconnectAttempts
    }
    if (config.reconnectBaseDelay !== undefined) {
      this.reconnectBaseDelay = config.reconnectBaseDelay
    }
    if (config.reconnectMaxDelay !== undefined) {
      this.reconnectMaxDelay = config.reconnectMaxDelay
    }
    if (config.autoReconnect !== undefined) {
      this.autoReconnect = config.autoReconnect
    }
  }

  // 注册消息处理器
  onMessage(handlerId, handler) {
    this.messageHandlers.set(handlerId, handler)
  }

  // 移除消息处理器
  removeMessageHandler(handlerId) {
    this.messageHandlers.delete(handlerId)
  }

  // 获取连接状态
  isConnected() {
    return this.eventSource !== null && this.eventSource.readyState === EventSource.OPEN
  }

  // 获取当前用户ID
  getCurrentUserId() {
    return this.userId
  }
}

// 创建单例实例
const sseClient = new SseClient()
export default sseClient
