import { setStore, getStore } from 'utils/store';
import website from '@/config/website';

const schoolWel = website.schoolFirstPage;

const school = {
  state: {
    schoolWel: schoolWel,
    schoolInfo: getStore({ name: 'schoolInfo' }) || {}
  },
  mutations: {
    SET_SCHOOL_WEL: (state, wel) => {
      state.schoolWel = wel;
    },
    SET_SCHOOL_INFO: (state, info) => {
      state.schoolInfo = info;
      setStore({
        name: 'schoolInfo',
        content: info,
        type: 'local'
      })
    }
  },
  actions: {
    updateSchoolWel({ commit }) {
      const newSchoolWel = website.schoolFirstPage;
      commit('SET_SCHOOL_WEL', newSchoolWel);
    },
    updateSchoolInfo({ commit }, info) {
      commit('SET_SCHOOL_INFO', info);
    }
  }
};

export default school;
