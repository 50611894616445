export default {
  height: 'auto',
  calcHeight: 30,
  tip: false,
  searchShow: true,
  searchMenuSpan: 6,
  border: true,
  index: true,
  viewBtn: true,
  selection: true,
    menuType: 'icon',
  stripe: true,
  dialogClickModal: false,
  column: [
    {
      label: '主键',
      prop: 'id',
      type: 'input',
      display: false,
      addDisplay: false,
      editDisplay: false,
      viewDisplay: false,
      hide: true,
    },
    {
      label: '租户ID',
      prop: 'tenantId',
      type: 'input',
      display: false,
      addDisplay: false,
      editDisplay: false,
      viewDisplay: false,
      hide: true,
    },
    {
      label: '事务所ID',
      prop: 'firmId',
      type: 'input',
      display: false,
      addDisplay: false,
      editDisplay: false,
      viewDisplay: false,
      hide: true,
    },
    {
      label: '企业名称',
      prop: 'enterpriseName',
      type: 'input',
      disabled: true,
      // display: false,
      addDisplay: false,
      editDisplay: false,
      viewDisplay: false,
      hide: true,
      required: true,
    },
    {
      label: '税号',
      prop: 'creditCode',
      type: 'input',
      disabled: true,
    },
    {
      label: '项目名称',
      prop: 'projectName',
      type: 'input',
      rules: [
        {
          required: true,
          message: '请输入项目名称',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '所属年度',
      prop: 'year',
      type: 'input',
      rules: [
        {
          required: true,
          message: '请输入年度',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '服务类型',
      prop: 'category',
      type: 'select',
      dicUrl: '/sc-system/dict/dictionary?code=tax_project_category',
      dataType: 'number',
      props: {
        label: 'dictValue',
        value: 'dictKey',
      },
      rules: [
        {
          required: true,
          message: '请选择服务类型',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '企业ID',
      prop: 'enterpriseId',
      type: 'input',
      display: false,
      addDisplay: false,
      editDisplay: false,
      viewDisplay: false,
      hide: true,
    },
    {
      label: '模板',
      prop: 'workpaperTemplateId',
      type: 'input',
      display: false,
      addDisplay: false,
      editDisplay: false,
      viewDisplay: false,
      hide: true,
      rules: [
        {
          required: true,
          message: '请选择模板',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '拥有者',
      prop: 'owner',
      type: 'input',
      display: false,
      addDisplay: false,
      editDisplay: false,
      viewDisplay: false,
      hide: true,
    },
    {
      label: '创建人',
      prop: 'createUser',
      type: 'input',
      display: false,
      addDisplay: false,
      editDisplay: false,
      viewDisplay: false,
      hide: true,
    },
    {
      label: '创建部门',
      prop: 'createDept',
      type: 'input',
      display: false,
      addDisplay: false,
      editDisplay: false,
      viewDisplay: false,
      hide: true,
    },
    {
      label: '创建时间',
      prop: 'createTime',
      type: 'input',
      display: false,
      addDisplay: false,
      editDisplay: false,
      viewDisplay: false,
      hide: true,
    },
    {
      label: '修改人',
      prop: 'updateUser',
      type: 'input',
      display: false,
      addDisplay: false,
      editDisplay: false,
      viewDisplay: false,
      hide: true,
    },
    {
      label: '修改时间',
      prop: 'updateTime',
      type: 'input',
      display: false,
      addDisplay: false,
      editDisplay: false,
      viewDisplay: false,
      hide: true,
    },
    {
      label: '状态',
      prop: 'status',
      type: 'input',
      display: false,
      addDisplay: false,
      editDisplay: false,
      viewDisplay: false,
      hide: true,
    },
    {
      label: '是否已删除',
      prop: 'isDeleted',
      type: 'input',
      display: false,
      addDisplay: false,
      editDisplay: false,
      viewDisplay: false,
      hide: true,
    },
  ],
};
