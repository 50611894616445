<template>
  <div class="navbar">
    <div class="nav-left">
      <h1 class="logo">{{ pageTitle }}</h1>
    </div>
    <div class="nav-right">
      <div class="search-icon">
        <i class="el-icon-search"></i>
      </div>
      <div class="notification">
        <i class="el-icon-bell"></i>
      </div>
      <el-popover
        placement="bottom"
        trigger="hover"
        :width="250"
      >
        <template #reference>
          <div class="avatar">
            <img :src="userInfo.avatar" :alt="userInfo.real_name" />
          </div>
        </template>
        <div class="user-info-popover">
          <div class="info-item">
            <span class="label" style="width: 70px;display: inline-block;">用户名：</span>
            <span>{{ userInfo.real_name }}</span>
          </div>
          <div class="info-item">
            <span class="label" style="width: 70px;display: inline-block;">事务所：</span>
            <span>{{ firmInfo?.firmName || '-' }}</span>
          </div>
          <div class="info-item">
            <span class="label" style="width: 70px;display: inline-block;">角色：</span>
            <span>{{ formatRoleName(userInfo.role_name) || '-' }}</span>
          </div>
          <div class="action-buttons">
            <router-link to="/firm/user/info" class="profile-button">
              <el-icon>
                <User />
              </el-icon>
              <span>个人中心</span>
            </router-link>
            <div class="logout-button" @click="handleLogout">
              <el-icon>
                <SwitchButton />
              </el-icon>
              <span>退出账号</span>
            </div>
          </div>
        </div>
      </el-popover>
    </div>
  </div> 
  <div class="avue-top" style="display: none;">
    <div class="top-bar__left">
      <div
        class="avue-breadcrumb"
        :class="[{ 'avue-breadcrumb--active': isCollapse }]"
        v-if="setting.collapse && !isHorizontal"
      >
        <i class="icon-navicon" @click="setCollapse"></i>
      </div>
    </div>
    <div class="top-bar__title">
      <router-link to="/firm/index" class="home-link">事务所主页</router-link>
      <!-- <top-menu ref="topMenu" v-if="setting.menu"></top-menu> -->
      <!-- <top-search class="top-bar__item" v-if="setting.search"></top-search> -->
    </div>
    <div class="top-bar__right">
      <!-- <div class="top-bar__item" v-if="setting.debug">
        <firm-top-notification></firm-top-notification>
      </div> -->
      <div class="top-user">
        <img class="top-bar__img" :src="userInfo.avatar" />
        <el-dropdown>
          <span class="el-dropdown-link">
            {{ userInfo.real_name }}
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <router-link to="/">{{ $t('navbar.dashboard') }}</router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/firm/user/info">{{ $t('navbar.userinfo') }}</router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                {{ formatRoleName(userInfo.role_name) || '-' }}
              </el-dropdown-item>
              <el-dropdown-item @click="logout" divided
                >{{ $t('navbar.logOut') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
          <!-- <top-setting></top-setting> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import firmTopNotification from './firm-top-notification.vue';
import { User, SwitchButton } from '@element-plus/icons-vue'

export default {
  components: {
    firmTopNotification,
    User,
    SwitchButton
  },
  name: 'top',
  data() {
    return {};
  },
  filters: {},
  created() {},
  computed: {
    ...mapGetters([
      'setting',
      'userInfo',
      'firmInfo',
      'tagWel',
      'tagList',
      'isCollapse',
      'tag',
      'logsLen',
      'logsFlag',
      'isHorizontal',
      'tenantInfo'
    ]),
  },
  methods: {
    setCollapse() {
      this.$store.commit('SET_COLLAPSE');
    },
    handleLogout() {
      this.$store.dispatch('LogOut').then(() => {
        this.redirectToDomain();
      });
    },
    formatRoleName(roleName) {
      if (!roleName) return '-';
      
      const roles = roleName.split(',').map(role => role.trim());
      const formattedRoles = roles.map(role => {
        if (role.includes('admin') || role.includes('administrator')) {
          return '管理员';
        } else if (role.includes('zxs')) {
          return '咨询师';
        } else {
          return role;
        }
      });
      
      return formattedRoles.join('、');
    },
    redirectToDomain() {
      // 检查是否在开发环境
      if (process.env.NODE_ENV === 'development') {
        window.location.href = 'http://localhost:2888/portal.html';
        return;
      }
      
      let url = this.tenantInfo.domain;
      
      // 如果不是完整的 URL，添加协议
      if (!url.match(/^https?:\/\//)) {
        url = `https://${url}`;
      }
      
      try {
        // 验证 URL 格式是否正确
        new URL(url);
        window.location.href = url;
      } catch (e) {
        console.error('Invalid domain:', url);
      }
    },
    logout() {
      this.$confirm(this.$t('logoutTip'), this.$t('tip'), {
        confirmButtonText: this.$t('submitText'),
        cancelButtonText: this.$t('cancelText'),
        type: 'warning',
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          this.$router.push({ path: '/portal' });
        });
      });
    },
  },
  props: {
    pageTitle: {
      type: String,
      default: '事务所主页'
    }
  },
};
</script>

<style lang="scss" scoped>
.top-bar__title {
  display: flex;
  align-items: center;
  height: 80%;
}

.home-link {
  font-size: 11.2px;
  color: #606266;
  text-decoration: none;
  padding: 4px 9.6px;
  border-radius: 3.2px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  height: 25.6px;
  
  &:hover {
    color: #409EFF;
    background-color: rgba(64, 158, 255, 0.1);
  }
}
/* 添加新的样式 */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12.8px 19.2px;
  background: transparent;
  box-shadow: 0px rgba(0, 0, 0, 0.05);
}

.nav-right {
  display: flex;
  align-items: center;
  gap: 19.2px;
}

.logo {
  font-size: 19.2px;
  color: #0F1B41;
  margin: 0;
}

.search-icon, .notification {
  cursor: pointer;
  font-size: 16px;
  color: #666;
}

.avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11.2px 0px;
  color: #17307A;
}

.filters {
  display: flex;
  align-items: center;
  color: #0F1B41;
  gap: 9.6px;
}
.header-controls {
  display: flex;
  align-items: center;
  gap: 12.8px;
}

.view-controls {
  display: flex;
  align-items: center;
  gap: 6.4px;
}

.view-switcher {
  display: flex;
  gap: 3.2px;
}

.filter-btn,
.list-view,
.grid-view {
  padding: 6.4px;
  border-radius: 6.4px;
}

.grid-view {
  background-color: #2F3CED;
  color: white;
}

.user-info-popover {
  padding: 6.4px;

  .info-item {
    margin-bottom: 6.4px;
    font-size: 11.2px;
    
    &:last-child {
      margin-bottom: 0;
    }

    .label {
      color: #909399;
      margin-right: 6.4px;
    }
  }

  .action-buttons {
    margin-top: 12.8px;
    display: flex;
    flex-direction: column;
    gap: 6.4px;
  }

  .profile-button {
    padding: 6.4px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6.4px;
    cursor: pointer;
    border-radius: 3.2px;
    color: #409EFF;
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
      background-color: #ECF5FF;
    }

    .el-icon {
      font-size: 12.8px;
    }

    span {
      font-size: 11.2px;
    }
  }

  .logout-button {
    padding: 6.4px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6.4px;
    cursor: pointer;
    border-radius: 3.2px;
    color: #F56C6C;
    transition: all 0.3s ease;

    &:hover {
      background-color: #FEF0F0;
    }

    .el-icon {
      font-size: 12.8px;
    }

    span {
      font-size: 11.2px;
    }
  }
}
</style>
