import { setStore, getStore } from 'utils/store';
import website from '@/config/website';

const tagWel = website.firmFirstPage;
// 定义常量
const TAG_FIRM_LIST = 'tagFirmList';
const TAG_FIRM = 'tagFirm';

const navs = {
  state: {
    tagFirmList: getStore({ name: TAG_FIRM_LIST }) || [],
    tagFirm: getStore({ name: TAG_FIRM }) || {},
    tagFirmWel: tagWel,
  },
  mutations: {
    ADD_FIRM_TAG: (state, action) => {
      if (typeof action.name == 'function') action.name = action.name(action.query);
      state.tagFirm = action;
      setStore({ name: TAG_FIRM, content: state.tagFirm });
      if (state.tagFirmList.some(ele => ele.fullPath == action.fullPath)) return;
      state.tagFirmList.push(action);
      setStore({ name: TAG_FIRM_LIST, content: state.tagFirmList });
    },
    DEL_FIRM_TAG: (state, action) => {
      state.tagFirmList = state.tagFirmList.filter(item => {
        return item.fullPath !== action.fullPath;
      });
      setStore({ name: TAG_FIRM_LIST, content: state.tagFirmList });
    },
    DEL_FIRM_TAG_ALL: (state, tagList = []) => {
      state.tagFirmList = tagList;
      setStore({ name: TAG_FIRM_LIST, content: state.tagFirmList });
    },
    DEL_FIRM_TAG_OTHER: state => {
      state.tagFirmList = state.tagFirmList.filter(item => {
        return [state.tagFirm.fullPath, website.firmFirstPage.path].includes(item.fullPath);
      });
      setStore({ name: TAG_FIRM_LIST, content: state.tagFirmList });
    },
  },
};
export default navs;
