<template>
    <div class="app-layout">
  <SideMenu />
    <div class="main-content">
      <top ref="top" :pageTitle="currentPageTitle" />
      <div id="avue-view" v-show="!isSearch" v-if="isRefresh">
          <router-view #="{ Component }">
            <keep-alive :include="$store.getters.tagsKeep">
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </div>
    </div>
  </div>
  <!--
  <div :class="{ 'avue--collapse': isCollapse }">
    <div class="avue-layout" :class="{ 'avue-layout--horizontal': isHorizontal }">
      <div class="avue-sidebar" v-show="validSidebar">
       
        <logo />
        <sidebar />
      </div>
      <div class="avue-main">
      
        <top ref="top" />
      
        <tags ref="firmTags" />
        <search class="avue-view" v-show="isSearch"></search>
       
        <div id="avue-view" v-show="!isSearch" v-if="isRefresh">
          <router-view #="{ Component }">
            <keep-alive :include="$store.getters.tagsKeep">
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </div>
      </div>
    </div>
 
  </div>-->
</template>

<script>
import index from '@/mixins/index';
import wechat from './wechat.vue';
//import { validatenull } from 'utils/validate';
import { mapGetters } from 'vuex';
import tags from './firmTags.vue';
import search from './search.vue';
import logo from './logo.vue';
import top from './top/firmIndex.vue';
import sidebar from './sidebar/firmIndex.vue';
import SideMenu from '@/components/layout/SideMenu.vue'
export default {
  mixins: [index],
  components: {
    top,
    logo,
    tags,
    search,
    sidebar,
    wechat,
    SideMenu
  },
  name: 'index',
  provide() {
    return {
      index: this,
    };
  },
  computed: {
    ...mapGetters([
      'isHorizontal',
      'isRefresh',
      'isLock',
      'isCollapse',
      'isSearch',
      'menu',
      'setting',
      'userInfo',
    ]),
    validSidebar() {
      return !(
        (this.$route.meta || {}).menu === false || (this.$route.query || {}).menu === 'false'
      );
    },
  },
  props: [],
  data() {
    return {
      currentPageTitle: '事务所主页' // 默认标题
    }
  },
  methods: {
    //打开菜单
    openMenu(item = {}) {
      this.$store.dispatch('GetMenuByVisibilityScope', item.visibilityScope).then(data => {
        if (data.length !== 0) {
          this.$router.$avueRouter.formatRoutes(data, true);
        }
      });
    },
  },
  mounted() {
    // 页面加载时，从 sessionStorage 恢复标题
    const savedTitle = sessionStorage.getItem('pageTitle');
    if (savedTitle) {
      this.currentPageTitle = savedTitle;
    }
  },
  watch: {
    // 监听路由变化更新标题
    '$route'(to) {
      this.currentPageTitle = to.name || '事务所主页';
      // 将标题存储到 sessionStorage
      sessionStorage.setItem('pageTitle', this.currentPageTitle);
    }
  }
};
</script>

<style scoped>
.app-layout {
  display: flex;
  min-height: 100vh;
  background-color: #F5F7FD;
}

.main-content {
  flex: 1;
  padding: 24px;
}
</style>
