import { setStore, getStore } from '@/utils/store';

const projectList = {
  state: {
    needRefresh: getStore({
      name: 'needRefresh'
    }) || false
  },
  mutations: {
    SET_NEED_REFRESH: (state, status) => {
      state.needRefresh = status;
      setStore({
        name: 'needRefresh',
        content: status
      });
    }
  },
  actions: {
    setNeedRefresh({ commit }, status) {
      commit('SET_NEED_REFRESH', status);
    }
  }
};

export default projectList;