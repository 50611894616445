<template>
  <div class="avue-logo">
    <transition name="fade">
      <span v-if="getScreen(isCollapse)" class="avue-logo_subtitle" key="0">
        {{ website.logo }}
      </span>
    </transition>
    <transition-group name="fade">
      <template v-if="getScreen(!isCollapse)">
        <!--{{ website.indexTitle }} -->
        <span class="avue-logo_title with-bg" key="1"> </span>
      </template>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'logo',
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters(['isCollapse']),
  },
  methods: {},
};
</script>
<style scoped>
.with-bg {
  background-image: url('/img/de-logo.png'); /* 替换为您的背景图片路径 */
  background-size: 90% ; /* 根据需要调整背景图片的大小 */
  background-position: center; /* 根据需要调整背景图片的位置 */
  display: inline-block; /* 确保元素是块级元素，以便应用背景图片 */
  padding: 10px; /* 根据需要调整内边距 */
  background-repeat: no-repeat; /* 防止背景图片重复 */
  width: 80%;
  height: 50px;
  padding-top: 40px;
}
</style>
