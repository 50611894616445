<template>
    <div class="ai-workspace">
      <div class="top-bar">
        <button class="run-button">运行页面</button>
        <span class="status">就绪</span>
        <select class="options">
          <option>重置</option>
        </select>
        <span class="schedule">定时任务</span>
        <span class="share">分享</span>
      </div>
  
      <h1>欢迎</h1>
      <p>请在下面的AI模块中输入指令，或者参考"命令方式"中的例子</p>
  
      <div class="ai-module">
        <div class="ai-result" v-for="result in aiResults" :key="result.id">
          <div class="result-header">
            <span class="success-icon">已完成！请检查结果是否正确</span>
          </div>
          <div class="ai-input">
            <span class="ai-icon">AI</span>
            <span>{{ result.input }}</span>
          </div>
          <div class="result-content">
            <div class="tab-header">
              <span class="tab active">结果</span>
              <span class="tab">详细步骤</span>
            </div>
            <div class="result-text">
              <p v-for="(line, index) in result.output" :key="index">{{ line }}</p>
              <p class="timestamp">上次运行: {{ result.timestamp }}</p>
            </div>
          </div>
          <div class="action-buttons">
            <button class="adjust">微调</button>
            <button class="rerun">重新运行</button>
            <button class="edit">修复</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AiWorkspace',
    data() {
      return {
        aiResults: [
          {
            id: 1,
            input: '生成两个1-100的随机数',
            output: [
              '生成的第一个随机数是: 40',
              '生成的第二个随机数是: 34'
            ],
            timestamp: '2024-10-08 09:44:05.769'
          }
        ]
      }
    }
  }
  </script>
  
  <style scoped>
  .ai-workspace {
    padding: 20px;
    background-color: #ffffff;
  }
  
  .top-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .run-button {
    background-color: #333;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    margin-right: 10px;
  }
  
  .status {
    color: #4CAF50;
    margin-right: 10px;
  }
  
  .options, .schedule, .share {
    margin-right: 10px;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .ai-module {
    margin-top: 20px;
  }
  
  .ai-result {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  .result-header {
    background-color: #9f89e5;
    color: white;
    padding: 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  
  .ai-input {
    background-color: #f5f5f5;
    padding: 10px;
    display: flex;
    align-items: center;
  }
  
  .ai-icon {
    background-color: #2196F3;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    margin-right: 10px;
  }
  
  .result-content {
    padding: 10px;
  }
  
  .tab-header {
    margin-bottom: 10px;
  }
  
  .tab {
    margin-right: 10px;
    color: #888;
    cursor: pointer;
  }
  
  .tab.active {
    color: #333;
    font-weight: bold;
  }
  
  .result-text {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
  }
  
  .timestamp {
    font-size: 12px;
    color: #888;
    margin-top: 10px;
  }
  
  .action-buttons {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
  }
  
  .action-buttons button {
    margin-left: 10px;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    background-color: #f0f0f0;
    color: #333;
    cursor: pointer;
  }
  
  .action-buttons button:hover {
    background-color: #e0e0e0;
  }
  </style>