<!--项目列表-->
<template>
  <div>
    <div class="project">
      <div class="flex-default jc-sb padding-10 line-30 border-bottom align-center">
        <b>{{ website.firmProjectEnterpriseEncrypt ? encryptEnterpriseName(aiIndex.enterpriseDetail.enterpriseName) : aiIndex.enterpriseDetail.enterpriseName }}</b>
        <i class="iconfont iconicon_more"></i>
      </div>
      <div class="flex-default jc-sb padding-10 line-30 border-bottom align-center">
        <i class="icon"><b>项目 project</b></i> 
        <i class="iconfont iconicon_more"></i>
      </div>
      <ul class="padding-10">
        <li
          class="create-project-btn"
          @click="handleCreateProject"
        >
          <el-icon class="plus-icon"><Plus /></el-icon>
          <span>新建项目</span>
          <span class="sub-text">New Project</span>
        </li>
        <li
          v-for="(item, index) in projects"
          :class="['margin-b10 grey-border fs-14 padding-10 border-radius8 saber-relative', { 'border-sky-500': ''=== item.id}]"
          @click="selectProject(item)"
        >
          <p class="line-25 pointer">{{ item.projectName }}</p>
          <!-- <p class="line-25 fc-999">{{item.desc}}</p> -->
          <div class="edit align-center text-center jc-center" @click.stop="handleProjectEdit(item)">
            <i class="iconfont iconicon_edit">edit</i>
          </div>
          <div class="delete align-center text-center jc-center" @click.stop="handleProjectRemove(item)">
            <i class="iconfont iconicon_delete"></i>
          </div>
        </li>
      </ul>
    </div>
    <!--      新建弹窗-->
    <el-dialog v-model="dialogFormVisible" title="新建项目" width="90%">
      <avue-form
        ref="formRef"
        :option="projectOption"
        v-model="projectForm"
        @submit="handleProjectSubmit"
      >
        <template #year="{ disabled, size }">
          <el-date-picker
            v-model="projectForm.year"
            type="year"
            placeholder="选择年份"
            value-format="YYYY"
          />  
        </template>
        <!-- 添加模板选择 -->
        <template #workpaperTemplateId v-if="projectForm.category === 1">
          <el-select 
            v-model="projectForm.workpaperTemplateId"
            placeholder="请选择模板"
            v-if="projectForm.category === 1"
            required
          >
            <el-option
              v-for="item in workpaperTemplates"
              :key="item.id"
              :label="(item.templateType === 'system' ? '（系统）' : '（事务所）') + item.originalName"
              :value="item.id"
            />
          </el-select>
        </template>
      </avue-form>
    </el-dialog>
    <!-- 编辑项目弹窗 -->
    <el-dialog v-model="editDialogFormVisible" title="编辑项目" width="90%">
      <avue-form
        ref="editFormRef"
        :option="projectOption"
        v-model="editProjectForm"
        @submit="handleProjectUpdate"
      >
      <template #year="{ disabled, size }">
        <el-date-picker
          v-model="editProjectForm.year"
          type="year"
          placeholder="选择年份"
          value-format="YYYY"
        />  
      </template>
    </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getList as getProjectList,
  add as addProject,
  update as updateProject,
  remove as deleteProject,
} from '@/api/firmProject/firmProject';
import { fetchFirmWorkpapersAndSystemWorkpapers } from '@/api/project/projectapi';
import projectOption from '@/option/firmProject/firmProject';
import website from '@/config/website'

export default {
  inject: ['aiIndex'],
  name: 'projectList',
  data() {
    return {
      website,
      // 新建项目弹窗
      dialogFormVisible: false,
      editDialogFormVisible: false, // 编辑项目弹窗
      formLabelWidth: '120px',
      // 新项目数据设置
      projectOption: projectOption,
      projectForm: {},
      editProjectForm: {}, // 编辑项目表单
      projects: [],
      activeProject: null,
      workpaperTemplates: [], // 存储模板列表
    };
  },
  // 监视，如果aiIndex.enterpriseDetail有值，赋值给projectForm
  watch: {
    // 监视 aiIndex 对象中的 enterpriseDetail 属性
    'aiIndex.enterpriseDetail': {
      handler(newVal) {
        // 只有在 newVal 有实际值的情况下才进行赋值操作
        if (newVal && Object.keys(newVal).length > 0) {
          this.projectForm = {
            firmId: newVal.firmId,
            enterpriseId: newVal.id,
            enterpriseName: newVal.enterpriseName,
            creditCode: newVal.creditCode,
          };
        }
      },
      deep: true, // 使用深度监听，以便捕获对象内部的变化
      immediate: true, // 立即执行，以便初始化时就进行赋值
    },
    // 监听服务类型变化
    'projectForm.category': {
      handler(newVal) {
        if (newVal === 1) {
          // 当选择企业年度税审时，获取对应的模板
          this.loadWorkpaperTemplates(this.aiIndex.enterpriseDetail.firmId,1);
          this.projectOption.column.find(item => item.prop === 'workpaperTemplateId').display = true;
        } else {
          this.workpaperTemplates = [];
          this.projectForm.workpaperTemplateId = '';
          this.projectOption.column.find(item => item.prop === 'workpaperTemplateId').display = false;
        }
      }
    },
  },
  mounted() {
    // 获取项目列表
    this.currentProject
    this.loadProjectList();
  },
  computed: {
      currentProject() {
        const currentProject = this.$store.getters.currentProject(this.$route.query.enterpriseId);
        if(currentProject){
          this.activeProject = currentProject;
        }
        return currentProject;
      },
  },
  methods: {
    encryptEnterpriseName(name) {
      if (!name) return name
      
      if (name.length <= 3) {
        // 如果名称长度小于等于3，只显示最后一个字符
        return '*'.repeat(name.length - 1) + name.slice(-1)
      } else {
        // 如果长度大于3，保留第三个字符，其他用*替换
        const stars = '*'.repeat(name.length - 1)
        return stars.slice(0, 2) + name.charAt(2) + stars.slice(3)
      }
    },
    loadProjectList() {
      getProjectList(1, 100, { enterpriseId: this.$route.query.enterpriseId }).then(res => {
        const result = res.data;
        if (result.code === 200) {
          this.projects = result.data.records;
        } else {
          this.$message.error(result.message);
        }
      });
    },
    // 加载模板列表
    loadWorkpaperTemplates(firmId,taxProjectCategory) {
      fetchFirmWorkpapersAndSystemWorkpapers(firmId,taxProjectCategory).then(res => {
        if (res.data.code === 200) {
          this.workpaperTemplates = res.data.data;
        } else {
          this.$message.error(res.data.message || '获取模板列表失败');
        }
      });
    },
    // 新建项目确定
    handleProjectSubmit(form, done) {
        // 检查如果模板选择是显示的但未选择模板
        const workpaperTemplateField = this.projectOption.column.find(item => item.prop === 'workpaperTemplateId');
        if (workpaperTemplateField?.display && !this.projectForm.workpaperTemplateId) {
            this.$message.error('请选择模板');
            done();
            return;
        }

        // 确保基础数据存在
        const baseData = {
            firmId: this.aiIndex.enterpriseDetail?.firmId,
            enterpriseId: this.aiIndex.enterpriseDetail?.id,
            enterpriseName: this.aiIndex.enterpriseDetail?.enterpriseName,
            creditCode: this.aiIndex.enterpriseDetail?.creditCode,
        };

        // 合并表单数据
        const submitData = {
            ...baseData,
            ...this.projectForm
        };

        // 验证必要字段
        if (!submitData.enterpriseId) {
            this.$message.error('企业信息不完整，请刷新页面重试');
            done();
            return;
        }

        // 弹出确认窗口
        this.$confirm('项目数据确认提交后无法修改，是否确认提交？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            // 用户确认后提交
            addProject(submitData).then(res => {
                const result = res.data;
                if (result.code === 200) {
                    this.$message.success('新建项目成功');
                    this.dialogFormVisible = false;
                    
                    // 重置表单
                    this.$refs.formRef.resetForm();
                    
                    // 重新初始化表单数据（使用函数来确保数据一致性）
                    this.initProjectForm();
                    
                    done();
                    this.loadProjectList();
                } else {
                    this.$message.error(result.message);
                    done();
                }
            }).catch(error => {
                done();
            });
        }).catch(() => {
            // 用户取消
            done();
        });
    },
    // 添加初始化表单的方法
    initProjectForm() {
        if (this.aiIndex.enterpriseDetail) {
            this.projectForm = {
                firmId: this.aiIndex.enterpriseDetail.firmId,
                enterpriseId: this.aiIndex.enterpriseDetail.id,
                enterpriseName: this.aiIndex.enterpriseDetail.enterpriseName,
                creditCode: this.aiIndex.enterpriseDetail.creditCode,
            };
        }
    },
    handleProjectEdit(project) {
      this.editProjectForm = { ...project };
      this.editDialogFormVisible = true;
    },
    handleProjectUpdate(form,done) {
      // 调用更新项目的API
      updateProject(this.editProjectForm).then(res => {
        const result = res.data;
        if (result.code === 200) {
          this.$message.success('项目更新成功');
          this.editDialogFormVisible = false;
          // 重置编辑表单
          this.$refs.editFormRef.resetForm();
          this.editProjectForm = {};
          this.loadProjectList();
        } else {
          this.$message.error(result.message);
        }
        done();
      });
    },
    handleProjectRemove(project) {
      this.$confirm('此操作将永久删除该项目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        // 删除项目
        deleteProject(project.id).then(res => {
          const result = res.data;
          if (result.code === 200) {
            this.$message.success('删除成功');
            this.loadProjectList();
            // 设置需要刷新的状态
            this.$store.dispatch('setNeedRefresh', true);
          }
        });
      });
    },
    selectProject(project) {
      this.activeProject = project;
      // 将项目数据转换为 URL 参数
      const params = new URLSearchParams({
        enterpriseId: project.enterpriseId,
        projectId: project.id,
        projectName: project.projectName,
        // 添加其他需要传递的项目信息
      }).toString();

      // 打开新窗口
      const newWindow = window.open(`/projectApp/index?${params}`, '_blank' );

      // 可选：如果需要在新窗口加载完成后执行一些操作
      if (newWindow) {
        newWindow.onload = () => {
          // 这里可以添加在新窗口加载完成后需要执行的代码
        };
      }

      // 仍然在当前页面更新状态
      this.activeProject = project;
      this.$store.commit('SET_ENTERPRISE_CURRENT_PROJECT', {
        enterpriseId: project.enterpriseId,
        project: project
      });
    },
    handleCreateProject() {
        this.initProjectForm(); // 先初始化表单
        this.dialogFormVisible = true; // 再显示弹窗
    },
  },
};
</script>

<style lang="scss" scoped>
.project {
  .creat {
    background-color: #cfddf7;
  }
  ul li div.edit, ul li div.delete {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    background-color: #fff;
    color: #666;
    display: none;
    transition: 0.3s;
  }
  ul li div.edit {
    right: 50px; /* Adjust position to the left of delete button */
  }
  ul li:hover div.edit, ul li:hover div.delete {
    display: flex;
  }
  ul li i:hover {
    color: #006cff;
    cursor: pointer;
  }
}
.pointer {
  cursor: pointer;
}
.icon { 
  background-image: url('/img/icon/12文件夹.png'); /* 替换为您的背景图片路径 */ 
}

.create-project-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 24px;
  background: linear-gradient(to right, #409EFF, #36a3ff);
  color: white;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 10px;
  box-shadow: 0 2px 12px 0 rgba(64, 158, 255, 0.1);
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 16px 0 rgba(64, 158, 255, 0.2);
  }

  .plus-icon {
    font-size: 20px;
  }

  .sub-text {
    margin-left: auto;
    opacity: 0.8;
    font-size: 14px;
  }
}
</style>
