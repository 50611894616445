import request from '@/axios';

/*系统模版底稿 */
export const fetchSystemWorkpapers = (taxProjectCategory) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/listWorkingPaperSysFiles',
    method: 'get',
    params: {
        taxProjectCategory:taxProjectCategory
    }
  })
}

/*企业模版底稿 */
export const fetchFirmWorkpapers = (firmId,taxProjectCategory) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/listWorkingPaperFirmFiles',
    method: 'get',
    params: {
      firmId:firmId,
      taxProjectCategory:taxProjectCategory
    }
  })
}

/*企业模版底稿和系统模版底稿 */
export const fetchFirmWorkpapersAndSystemWorkpapers = (firmId,taxProjectCategory) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/listWorkingPaperFirmFilesAndSysFiles',
    method: 'get',
    params: {
        firmId:firmId,
        taxProjectCategory:taxProjectCategory
    }
  })
}

/*项目模版底稿 */
export const fetchProjectWorkpapers = (project_id ) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/projectWorkingPaper',
    method: 'get',
    params: {
      projectId:project_id
    }
  })
}

/**
 * 删除项目财务信息附件
 * @param {string} projectId - 项目ID
 * @returns {Promise} - 返回请求的Promise对象
 */
export function removeProjectFinancialInfoAttach(projectId) {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/remove-project-financial-info-attach',
    method: 'post',
    params: {
      projectId:projectId
    }
  });
}

