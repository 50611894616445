import { setStore, getStore } from 'utils/store';
import website from '@/config/website';

const schoolFirstPage = website.schoolFirstPage;
// 定义常量
const TAG_COURSE_LIST = 'tagCourseList';
const TAG_COURSE = 'tagCourse';

const navs = {
  state: {
    tagCourseList: getStore({ name: TAG_COURSE_LIST }) || [],
    tagCourse: getStore({ name: TAG_COURSE }) || {},
    tagCourseWel: schoolFirstPage,
  },
  mutations: {
    ADD_COURSE_TAG: (state, action) => {
      if (typeof action.name == 'function') action.name = action.name(action.query);
      state.tagCourse = action;
      setStore({ name: TAG_COURSE, content: state.tagCourse });
      if (state.tagCourseList.some(ele => ele.fullPath == action.fullPath)) return;
      state.tagCourseList.push(action);
      setStore({ name: TAG_COURSE_LIST, content: state.tagCourseList });
    },
    DEL_COURSE_TAG: (state, action) => {
      state.tagCourseList = state.tagCourseList.filter(item => {
        return item.fullPath !== action.fullPath;
      });
      setStore({ name: TAG_COURSE_LIST, content: state.tagCourseList });
    },
    DEL_COURSE_TAG_ALL: (state, tagList = []) => {
      state.tagCourseList = tagList;
      setStore({ name: TAG_COURSE_LIST, content: state.tagCourseList });
    },
    DEL_COURSE_TAG_OTHER: state => {
      state.tagCourseList = state.tagCourseList.filter(item => {
        return [state.tagCourse.fullPath, website.schoolFirstPage.path].includes(item.fullPath);
      });
      setStore({ name: TAG_COURSE_LIST, content: state.tagCourseList });
    },
  },
};
export default navs;
