<template>
  <div class="reset-password-container">
    <div class="reset-password-content">
      <div class="left-side">
        <div class="logo">
          <img src="/img/de-logo4.png" alt="Focus logo">
        </div>
        <img src="/img/firm/login_left.png" alt="Login illustration" class="login-image">
      </div>
      <div class="right-side">
        <h2 class="platform-name">AI企业税审平台</h2>
        <div class="form-container">
          <!-- <h1 class="title">账号密码登录</h1> -->
          
          <div class="form-group">
            <input type="phone" id="phone" v-model="phone" placeholder="请输入手机号">
          </div>
          
          <div class="form-group verification-code-group">
            <input type="text" id="verificationCode" v-model="verificationCode" placeholder="请输入验证码">
            <button class="send-code-button" @click="sendVerificationCode" :disabled="cooldown > 0">
              {{ cooldown > 0 ? `${cooldown}s` : '发送验证码' }}
            </button>
          </div>
          
          <button class="send-button" @click="sendResetRequest">登录</button>
          
          <!-- 新增注册按钮 -->
          <button class="register-button" @click="goToRegister">新用户注册</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResetPassword',
  data() {
    return {
      phone: '',
      verificationCode: '',
      cooldown: 0
    }
  },
  methods: {
    sendResetRequest() {
      // 实现发送重置请求的逻辑
      console.log('Sending reset request for:', this.phone, 'with code:', this.verificationCode)
    },
    sendVerificationCode() {
      // 实现发送验证码的逻辑
      console.log('Sending verification code to:', this.phone)
      this.startCooldown()
    },
    startCooldown() {
      this.cooldown = 60
      const timer = setInterval(() => {
        this.cooldown--
        if (this.cooldown <= 0) {
          clearInterval(timer)
        }
      }, 1000)
    },
    
    goToRegister() {
      // 跳转到注册页面
      this.$router.push('/firm/regist')
    }
  }
}
</script>

<style scoped>
.reset-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #8a7eeb; /* 更柔和的紫色 */
}

.reset-password-content {
  display: flex;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  width: 80%;
  max-width: 1000px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.left-side {
  flex: 1;
  background-color: #f0f0ff;
  position: relative;
}

.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
}

.logo img {
  height: 40px;
}

.login-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.right-side {
  flex: 1;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center; /* 改为居中对齐 */
  align-items: center;
  position: relative;
}

.platform-name {
  position: absolute;
  top: 2rem;
  left: 2rem;
  font-size: 1.4rem;
  color: #8a7eeb; /* 更新平台名称颜色以匹配新的背景色 */
  margin: 0;
  font-weight: bold;
}

.form-container {
  width: 100%;
  max-width: 400px;
  /* 移除 margin-top */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-align: center;
}

.form-group {
  margin-bottom: 1rem;
}

input {
  width: 100%;
  padding: 0.8rem 1rem; /* 增加垂直和水平内边距 */
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 1rem;
}

.verification-code-group {
  display: flex;
  gap: 10px;
}

.verification-code-group input {
  flex-grow: 1;
}

.send-code-button {
  background-color: #8a7eeb;
  color: white;
  border: none;
  padding: 0.8rem 1rem; /* 调整发送验证码按钮的高度以匹配输入框 */
  border-radius: 20px;
  cursor: pointer;
  font-size: 0.9rem;
  white-space: nowrap;
  transition: background-color 0.3s ease;
}

.send-code-button:hover:not(:disabled) {
  background-color: #7a6dda; /* 稍微深一点的紫色 */
}

.send-code-button:disabled {
  background-color: #b3aaef;
  cursor: not-allowed;
}

.send-button, .register-button {
  background-color: #8a7eeb;
  color: white;
  border: none;
  padding: 0.9rem 1rem;
  border-radius: 20px;
  border: 2px solid #8a7eeb;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  margin-top: 1rem;
  transition: all 0.3s ease;
  height: 2.8rem; /* 添加固定高度 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.send-button:hover {
  background-color: #7a6dda;
}

.register-button {
  background-color: transparent;
  color: #8a7eeb;
}

.register-button:hover {
  background-color: #8a7eeb;
  color: white;
}

.back-link {
  display: block;
  text-align: center;
  margin-top: 1rem;
  color: #666;
  text-decoration: none;
}

/* 其他样式保持不变 */
</style>
